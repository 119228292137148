import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `

<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">AnnotationViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[AnnotationViewControllerDelegate annotationViewControllerSwitchToBookmarks:]</div>
<div class="difference"><span class="status removed">Removed</span> -[AnnotationViewControllerDelegate annotationViewControllerSwitchToOutline:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">AnnotEditTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[AnnotEditTool pageBoxInScreenPtsForPageNumber:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">BookmarkViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[BookmarkViewControllerDelegate bookmarkViewControllerSwitchToAnnotations:]</div>
<div class="difference"><span class="status removed">Removed</span> -[BookmarkViewControllerDelegate bookmarkViewControllerSwitchToOutline:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">OutlineViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[OutlineViewControllerDelegate outlineViewControllerSwitchToAnnotations:]</div>
<div class="difference"><span class="status removed">Removed</span> -[OutlineViewControllerDelegate outlineViewControllerSwitchToBookmarks:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> e_high_quality</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> e_ptflatten_high_quality</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl keyboardWillShow:rectToNotOverlapWith:topEdge:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController stopHideControlsTimer]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController stopAutomaticControlHidingTimer]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[Tool pageBoxInScreenPtsForPageNumber:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">ToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[ToolManagerDelegate toolManager:shouldSwitchToTool:]</div>
</div>

</div>
</body>
</html>

`


export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}

